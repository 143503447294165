import React, { useEffect } from "react";

import SectionAudiobook from "./SectionAudiobook";
import Menu from "../shared/Menu";
import Footer from "../shared/Footer.js";
import { Container, Content2Xl } from "components/misc/Layouts";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

export default () => {
  /*
   * Using gtag like this because we only want to use Google Analytics when Main Landing Page is rendered
   * Remove this part and the the gtag script inside public/index.html if you dont need google analytics
   */
  useEffect(() => {
    window.gtag("js", new Date());
    window.gtag("config", "UA-45799926-9");
  }, []);

  return (
    <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
      <Content2Xl>
        <AnimationRevealPage>
          <Menu />
          <SectionAudiobook />
        </AnimationRevealPage>
      </Content2Xl>
      <Footer />
    </Container>
  );
};
