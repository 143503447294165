const AUDIBLE_AUTHOR_PAGE =
  "https://www.audible.com/author/Grace-Cohen/B08V3CPXCJ";
const AMAZON_AUTHOR_PAGE = "https://amazon.com/author/gracecohen";
const BOOKSPROUT_TEAM_PAGE =
  "https://booksprout.co/reviewer/team/18202/grace-cohens-review-team";
const NICHE = { PARENTS: "Parents", TEENS: "Teens" };

const getBountyLinks = (us, ca, fr, de) => {
  return [
    {
      name: "Audible US",
      url: us,
    },
    {
      name: "Audible CA",
      url: ca,
    },
    {
      name: "Audible FR",
      url: fr,
    },
    {
      name: "Audible DE",
      url: de,
    },
  ];
};

const BTN_TEXT_DOWNLOAD = "Download";
const BTN_TEXT_BUY_ON_AMAZON = "Buy on Amazon";
const freeEbooks = {
  "For Parents": [
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/n26eovvqho/cover_md.jpg",
        url: "https://dl.bookfunnel.com/2oo8v8yiv6",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/xj8iazob1o/cover_md.jpg",
        url: "https://dl.bookfunnel.com/xkc54crf8g",
      },
    },
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/x03spmja7b/cover_md.jpg",
        url: "https://dl.bookfunnel.com/o0tgn6kbsa",
      },
    },
  ],
  "For Teens": [
    {
      btnText: BTN_TEXT_DOWNLOAD,
      niche: NICHE.TEENS,
      kdp: {
        imageSrc:
          "https://bookfunnelimages.com/images/covers/wu7g84zseu/cover_md.jpg",
        url: "https://dl.bookfunnel.com/s3hiwut9vr",
      },
    },
  ],
};

const titles = {
  "For Parents": [
    {
      name: "The Yell-Free Parent’s Guide to Disciplining an Explosive Child",
      siteCode: "tyfpg_explosive-child",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41UMKCC-3pL._SY346_.jpg",
        url: "https://mybook.to/tyfpg_explosive-child",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51Ev8Z6w2dL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B08Y68MCKT/?source_code=AUDFPWS0223189MWT-BK-ACX0-242544&ref=acx_bty_BK_ACX0_242544_rh_us",
          "https://www.audible.co.uk/pd/B08Y65L2JH/?source_code=AUKFrDlWS02231890H6-BK-ACX0-242544&ref=acx_bty_BK_ACX0_242544_rh_uk",
          "https://www.audible.fr/pd/B08Y681JRQ/?source_code=FRAORWS022318903B-BK-ACX0-242544&ref=acx_bty_BK_ACX0_242544_rh_fr",
          "https://www.audible.de/pd/B08Y65K7P4/?source_code=EKAORWS0223189009-BK-ACX0-242544&ref=acx_bty_BK_ACX0_242544_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/97976/the-yell-free-parents-guide-to-disciplining-an-explosive-child-no-drama-strategies-i-discovered-to",
      },
    },
    {
      name: "The Yell-Free Parent's Guide to Raising an Empathic Child",
      siteCode: "tyfpg_empathic-child",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41hu1fYZnuL.jpg",
        url: "https://mybook.to/tyfpg_empathic-child",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51Ce9paIs2S._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09562VCVZ/?source_code=AUDFPWS0223189MWT-BK-ACX0-258432&ref=acx_bty_BK_ACX0_258432_rh_us",
          "https://www.audible.co.uk/pd/B09561SRW1/?source_code=AUKFrDlWS02231890H6-BK-ACX0-258432&ref=acx_bty_BK_ACX0_258432_rh_uk",
          "https://www.audible.fr/pd/B0955VGKVG/?source_code=FRAORWS022318903B-BK-ACX0-258432&ref=acx_bty_BK_ACX0_258432_rh_fr",
          "https://www.audible.de/pd/B0955ZN5D8/?source_code=EKAORWS0223189009-BK-ACX0-258432&ref=acx_bty_BK_ACX0_258432_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98026/the-yell-free-parents-guide-to-raising-an-empathic-child-how-to-help-your-child-self-regulate-and-manage-feelings-effectively",
      },
    },
    {
      name: "Explosive to Empathic - 2 Books in 1 Bundle",
      siteCode: "tyfpg_bundle",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51OeCXJGlVS.jpg",
        url: "https://mybook.to/tyfpg_bundle",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/511+jrBCDZS._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B095YNWRNZ/?source_code=AUDFPWS0223189MWT-BK-ACX0-260084&ref=acx_bty_BK_ACX0_260084_rh_us",
          "https://www.audible.co.uk/pd/B095YNV2WL/?source_code=AUKFrDlWS02231890H6-BK-ACX0-260084&ref=acx_bty_BK_ACX0_260084_rh_uk",
          "https://www.audible.fr/pd/B095YP9SNL/?source_code=FRAORWS022318903B-BK-ACX0-260084&ref=acx_bty_BK_ACX0_260084_rh_fr",
          "https://www.audible.de/pd/B095YNBWP1/?source_code=EKAORWS0223189009-BK-ACX0-260084&ref=acx_bty_BK_ACX0_260084_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98050/explosive-to-empathic-2-books-in-1-bundle-the-yell-free-parents-complete-guide-to-raising-an-explosive-child",
      },
    },
    {
      name: "Anger Management for Explosive Parents",
      siteCode: "am4ep",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/519qnoeACDL._SY346_.jpg",
        url: "https://mybook.to/am4ep",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51ZBxhgw6gL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B097XLZ7PK/?source_code=AUDFPWS0223189MWT-BK-ACX0-265225&ref=acx_bty_BK_ACX0_265225_rh_us",
          "https://www.audible.co.uk/pd/B097XNSV46/?source_code=AUKFrDlWS02231890H6-BK-ACX0-265225&ref=acx_bty_BK_ACX0_265225_rh_uk",
          "https://www.audible.fr/pd/B097XMBQBX/?source_code=FRAORWS022318903B-BK-ACX0-265225&ref=acx_bty_BK_ACX0_265225_rh_fr",
          "https://www.audible.de/pd/B097XDWSHY/?source_code=EKAORWS0223189009-BK-ACX0-265225&ref=acx_bty_BK_ACX0_265225_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98031/anger-management-for-explosive-parents-how-to-parent-yourself-manage-your-emotions-and-raise-a-confident-and-warm-hearted-child",
      },
    },
    {
      name: "Emotional Intelligence for Parents",
      siteCode: "ei4p",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51zNIK5DEgL._SY346_.jpg",
        url: "https://mybook.to/ei4p",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51TTAM3N3xL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09DMWQH1D/?source_code=AUDFPWS0223189MWT-BK-ACX0-274973&ref=acx_bty_BK_ACX0_274973_rh_us",
          "https://www.audible.co.uk/pd/B09DMZJVRQ/?source_code=AUKFrDlWS02231890H6-BK-ACX0-274973&ref=acx_bty_BK_ACX0_274973_rh_uk",
          "https://www.audible.fr/pd/B09DMRWXDW/?source_code=FRAORWS022318903B-BK-ACX0-274973&ref=acx_bty_BK_ACX0_274973_rh_fr",
          "https://www.audible.de/pd/B09DMPM9PL/?source_code=EKAORWS0223189009-BK-ACX0-274973&ref=acx_bty_BK_ACX0_274973_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98039/emotional-intelligence-for-parents-a-guide-to-mastering-your-emotions-and-raising-an-emotionally-in",
      },
    },
    {
      name: "Anger Management and Emotional Intelligence for Parents",
      siteCode: "am4ep-ei4p",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51tVIBY+jaL.jpg",
        url: "https://mybook.to/am4ep-ei4p",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51nZPw-8eVL._SL500_.jpg",
        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09FVKRTLR/?source_code=AUDFPWS0223189MWT-BK-ACX0-276669&ref=acx_bty_BK_ACX0_276669_rh_us",
          "https://www.audible.co.uk/pd/B09FVFVT5B/?source_code=AUKFrDlWS02231890H6-BK-ACX0-276669&ref=acx_bty_BK_ACX0_276669_rh_uk",
          "https://www.audible.fr/pd/B09FVPVZ1D/?source_code=FRAORWS022318903B-BK-ACX0-276669&ref=acx_bty_BK_ACX0_276669_rh_fr",
          "https://www.audible.de/pd/B09FVK1SDM/?source_code=EKAORWS0223189009-BK-ACX0-276669&ref=acx_bty_BK_ACX0_276669_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98053/anger-management-and-emotional-intelligence-for-parents-master-your-emotions-be-calmer-and-raise-an-emotionally-intelligent-child-2-books-in-1",
      },
    },
    {
      name: "Raising a Strong-Willed Child",
      siteCode: "rswc",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51JyPPgQQrL.jpg",
        url: "https://mybook.to/rswc",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51bLBWjY8PL._SL500_.jpg",

        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B09NMRW488/?source_code=AUDFPWS0223189MWT-BK-ACX0-289738&ref=acx_bty_BK_ACX0_289738_rh_us",
          "https://www.audible.co.uk/pd/B09NMSTC9Q/?source_code=AUKFrDlWS02231890H6-BK-ACX0-289738&ref=acx_bty_BK_ACX0_289738_rh_uk",
          "https://www.audible.fr/pd/B09NMSN4H8/?source_code=FRAORWS022318903B-BK-ACX0-289738&ref=acx_bty_BK_ACX0_289738_rh_fr",
          "https://www.audible.de/pd/B09NMSB3PS/?source_code=EKAORWS0223189009-BK-ACX0-289738&ref=acx_bty_BK_ACX0_289738_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98043/raising-a-strong-willed-child-parenting-children-who-live-by-their-own-rules-without-losing-your-mind",
      },
    },
    {
      name: "Alpha Female Bible",
      siteCode: "afb",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41Stbs4B3aL.jpg",
        url: "https://mybook.to/afb",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/415-uUpBpTL._SL500_.jpg",

        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B0B6GSN1C2/?source_code=AUDFPWS0223189MWT-BK-ACX0-315265&ref=acx_bty_BK_ACX0_315265_rh_us",
          "https://www.audible.co.uk/pd/B0B6H7LKR8/?source_code=AUKFrDlWS02231890H6-BK-ACX0-315265&ref=acx_bty_BK_ACX0_315265_rh_uk",
          "https://www.audible.fr/pd/B0B6GRF9C4/?source_code=FRAORWS022318903B-BK-ACX0-315265&ref=acx_bty_BK_ACX0_315265_rh_fr",
          "https://www.audible.de/pd/B0B6GSJ351/?source_code=EKAORWS0223189009-BK-ACX0-315265&ref=acx_bty_BK_ACX0_315265_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98045/alpha-female-bible",
      },
    },
    {
      name: "Self-Love Workbook for Moms for Free",
      siteCode: "slw4moms",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/41iQpoQX8+L.jpg",
        url: "https://mybook.to/slw4moms",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/41Z9G0-ruxL._SL500_.jpg",

        bountyLinks: getBountyLinks(
          "https://www.audible.com/pd/B0B58GRJ1F/?source_code=AUDFPWS0223189MWT-BK-ACX0-313527&ref=acx_bty_BK_ACX0_313527_rh_us",
          "https://www.audible.co.uk/pd/B0B58JWY2Q/?source_code=AUKFrDlWS02231890H6-BK-ACX0-313527&ref=acx_bty_BK_ACX0_313527_rh_uk",
          "https://www.audible.fr/pd/B0B58GMCBY/?source_code=FRAORWS022318903B-BK-ACX0-313527&ref=acx_bty_BK_ACX0_313527_rh_fr",
          "https://www.audible.de/pd/B0B58HGHYH/?source_code=EKAORWS0223189009-BK-ACX0-313527&ref=acx_bty_BK_ACX0_313527_rh_de"
        ),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/98047/self-love-workbook-for-moms-rediscover-the-joy-in-your-life-and-learn-to-love-yourself-again",
      },
    },
    {
      name: "Nonviolent Communication With Teens",
      siteCode: "nvcwt",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.PARENTS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51YGG-PzEyL.jpg",
        url: "https://mybook.to/nvcwt",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51l2Zcv639L._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/149419/nonviolent-communication-with-teens-parenting-techniques-i-learned-to-develop-a-deeper-connection-with-my-teenager-accurately-recognize-feelings-and-compassionately-resolve-conflicts",
      },
    },
  ],
  "For Teens": [
    {
      name: "Social Skills for Teens With Social Anxiety",
      siteCode: "ss4twsa",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.TEENS,
      kdp: {
        imageSrc: "https://m.media-amazon.com/images/I/51Ea-SPc0GL.jpg",
        url: "https://mybook.to/ss4twsa",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/51mdvNm-izL._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/149420/social-skills-for-teens-with-social-anxiety-manage-anxiety-overcome-the-fear-of-being-judged-feel-comfortable-in-any-social-situations-and-make-real-friends",
      },
    },
    {
      name: "Habilidades interpersonales para adolescentes con ansiedad social",
      siteCode: "ss4twsa-spanish",
      btnText: BTN_TEXT_BUY_ON_AMAZON,
      niche: NICHE.TEENS,
      kdp: {
        imageSrc:
          "https://m.media-amazon.com/images/I/81Vu4O4IYEL._SL1500_.jpg",
        url: "https://mybook.to/ss4twsa-spanish",
      },
      acx: {
        imageSrc: "https://m.media-amazon.com/images/I/519YW937o0L._SL500_.jpg",
        bountyLinks: getBountyLinks("", "", "", ""),
        bsPromoCodeLink:
          "https://booksprout.co/reviewer/review-copy/view/149422/habilidades-interpersonales-para-adolescentes-con-ansiedad-social",
      },
    },
  ],
};

export {
  freeEbooks,
  titles,
  AUDIBLE_AUTHOR_PAGE,
  AMAZON_AUTHOR_PAGE,
  BOOKSPROUT_TEAM_PAGE,
  NICHE,
};
