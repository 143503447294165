import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";

Modal.setAppElement("#root");

window.gtag("config", process.env.REACT_APP_GA_ID);
window.ml_account = window.ml("accounts", process.env.REACT_APP_ML_ID, process.env.REACT_APP_ML_FORM_ID, "load");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);